import {
  addCompraApi,
  getComprasAceFiltersApi,
  updateCompraApi,
  updateProductoApi,
} from "../../api";
import {
  finishLoadingCompras,
  setCompras,
  startLoadingCompras,
  startSavingCompra,
  startSavingProductos,
  updateCompra,
  updateProductos,
} from "./comprasSlice";

export const loadCompras = (api) => {
  return async (dispatch, getState) => {
    try {
      const { filterCompras } = getState().compras;
      dispatch(startLoadingCompras());
      const { data } = await getComprasAceFiltersApi(api, filterCompras);
      dispatch(setCompras(data));
    } catch (e) {
      console.log(`Compras - Thunks - loadCompras - Error ${e}`);
      dispatch(finishLoadingCompras());
    }
  };
};

export const saveCompra = (api) => {
  return async (dispatch, getState) => {
    try {
      let compraAux = {};
      const { activeCompra } = getState().compras;
      dispatch(startSavingCompra());
      //Actualización en el backEnd
      activeCompra.id === null
        ? (compraAux = await addCompraApi(api, activeCompra))
        : (compraAux = await updateCompraApi(api, activeCompra));
      //Actualización en el fronEnd
      dispatch(updateCompra(compraAux.data));
      return { ok: true, json: () => "Finalización Exitosa" };
    } catch (e) {
      return { ok: false, statusText: e.message };
    }
  };
};

export const saveProductos = (api, produdctoWillUpdate = []) => {
  return async (dispatch, getState) => {
    try {
      //Actualización en el fronEnd
      dispatch(startSavingProductos());

      let listProdActualizadosInBE = [];

      //Actualización en el backEnd
      listProdActualizadosInBE = await updateProductoApi(
        api,
        produdctoWillUpdate
      );

      dispatch(updateProductos(listProdActualizadosInBE.data));

      return { ok: true, json: () => "Finalización Exitosa" };
    } catch (e) {
      return { ok: false, statusText: e.message };
    }
  };
};
