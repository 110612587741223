import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import errorSlice from "./errorSlice";
import userSlice from "./userSlice";
import stockSlice from "./stockSlice";
import { farmaciasSlice } from "./farmacias";
import { laboratoriosSlice } from "./laboratorios";
import { auditoriasSlice } from "./auditorias";
import { comprasSlice } from "./compras";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    error: errorSlice.reducer,
    user: userSlice.reducer,
    stock: stockSlice.reducer,   
    farmacias: farmaciasSlice.reducer, 
    laboratorios: laboratoriosSlice.reducer, 
    auditorias: auditoriasSlice.reducer, 
    compras: comprasSlice.reducer,
  },
});

export default store;
