import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { ProductoItemRenglon } from "./ProductoItemRenglon";
import { getDetallesTotalesCompraByFarmaciaByIdApi } from "../../../api";
import usePrivateApi from "../../../hooks/usePrivateApi";
import { ProductoItemReglonDetFarmacias } from "./ProductoItemReglonDetFarmacias";

export const ProductoItem = () => {
  const { api } = usePrivateApi();
  const { activeProducto } = useSelector((state) => state.compras);
  const { producto } = activeProducto;

  const [loading, setLoading] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [detalleVtas, setDetalleVtas] = useState([]);
  const [detalleCompras, setDetalleCompras] = useState([]);
  const [detalleSugerido, setDetalleSugerido] = useState([]);
  const [detallesStock, setDetallesStock] = useState([]);

  const handleMoreDetails = async () => {
    setShowMoreDetails(!showMoreDetails);
    if (showMoreDetails) return;
    //verifico que si ya están cargados los datos no los vuelve a recuperar
    if (detalleVtas.length > 0) return;

    setLoading(true);
    const detallesXFarmacias = await getDetallesTotalesCompraByFarmaciaByIdApi(
      { api },
      activeProducto.idCompraAce,
      producto.id
    );
    setLoading(false);

    setDetalleVtas(
      detallesXFarmacias.data.map((detalle) => ({
        farmacia: detalle.farmacia,
        cantidad: detalle.cantidadPaquetesVtasPeriodo,
      }))
    );
    setDetalleCompras(
      detallesXFarmacias.data.map((detalle) => ({
        farmacia: detalle.farmacia,
        cantidad: detalle.cantidadPaquetesComprasPeriodo,
      }))
    );
    setDetallesStock(
      detallesXFarmacias.data.map((detalle) => ({
        farmacia: detalle.farmacia,
        cantidad: detalle.cantidadPaquetesStock,
      }))
    );
    setDetalleSugerido(
      detallesXFarmacias.data.map((detalle) => ({
        farmacia: detalle.farmacia,
        cantidad: detalle.cantidadPaquetesComprasSugerido,
      }))
    );
  };

  useEffect(() => {
    setDetalleVtas([]);
    setDetalleCompras([]);
    setDetallesStock([]);
    setDetalleSugerido([]);
    setShowMoreDetails(false);
  }, [activeProducto]);

  return (
    <>
      <div className="card my-4">
        <div className="card-header d-flex align-items-center">
          <span className="flex-grow-1"> Producto </span>

          {!loading ? (
            showMoreDetails ? (
              <Icon
                icon="material-symbols:chips"
                onClick={handleMoreDetails}
                color="#218657"
                width="20"
                height="20"
              />
            ) : (
              <Icon
                icon="material-symbols:add-box"
                onClick={handleMoreDetails}
                color="#218657"
                width="20"
                height="20"
              />
            )
          ) : (
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
        </div>
        <div className="card-body">
          <ProductoItemRenglon
            clave={"Id"}
            valor={producto.id}
            resaltar={true}
          />
          <ProductoItemRenglon
            clave={"Nombre"}
            valor={producto.nombre}
            resaltar={true}
          />
          <ProductoItemRenglon
            clave={"Presentacion"}
            valor={producto.presentacion}
          />
          <ProductoItemRenglon
            clave={"Codigo Barras"}
            valor={producto.codigoBarras}
          />
          <ProductoItemRenglon
            clave={"Laboratorio"}
            valor={producto.laboratorio.nombre}
          />
          <ProductoItemRenglon
            clave={"# Vta Período"}
            valor={activeProducto.cantidadPaquetesVentasPeriodo}
            showMoreDetails={true}
          />
          {showMoreDetails ? (
            <ProductoItemReglonDetFarmacias detalle={detalleVtas} />
          ) : (
            ""
          )}
          <ProductoItemRenglon
            clave={"# Compra Período"}
            valor={activeProducto.cantidadPaquetesComprasPeriodo}
            showMoreDetails={true}
          />
          {showMoreDetails ? (
            <ProductoItemReglonDetFarmacias detalle={detalleCompras} />
          ) : (
            ""
          )}
          <ProductoItemRenglon
            clave={"# Sugerido"}
            valor={activeProducto.cantidadPaquetesComprasSugerido}
            showMoreDetails={true}
          />
          {showMoreDetails ? (
            <ProductoItemReglonDetFarmacias detalle={detalleSugerido} />
          ) : (
            ""
          )}
          <ProductoItemRenglon
            clave={"# Stock Farm"}
            valor={activeProducto.cantidadPaquetesStockFarmacia}
            showMoreDetails={true}
          />
          {showMoreDetails ? (
            <ProductoItemReglonDetFarmacias detalle={detallesStock} />
          ) : (
            ""
          )}
          <ProductoItemRenglon
            clave={"# Stock Ace"}
            valor={activeProducto.cantidadPaquetesStockAce}
            showMoreDetails={true}
          />
        </div>
      </div>
    </>
  );
};
