import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";

const getRoles = (token) => {
  /*let token = localStorage.getItem("token");
  let decodedToken = jwt_decode(token);
  console.log("Decoded Token", decodedToken);
  let currentDate = new Date();

  // JWT exp is in seconds
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    console.log("Token expired.");
  } else {
    console.log("Valid token");
  }*/
  return jwt_decode(token).roles;
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    username: localStorage.getItem("username"),
    roles: [],
    loggedIn: localStorage.getItem("loggedIn"),
    token: null,
    rememberMe: true,
  },
  reducers: {
    loginSuccessful(state, action) {
      const { username, token, rememberMe } = action.payload;
      state.username = username;
      localStorage.setItem("username", username);

      state.token = token;

      state.rememberMe = rememberMe;
      localStorage.setItem("rememberMe", rememberMe);

      state.roles = getRoles(token);
      localStorage.setItem("roles", JSON.stringify(getRoles(token)));

      state.loggedIn = true;
      localStorage.setItem("loggedIn", true);
    },
    logout(state) {
      state.username = "";
      state.roles = [];
      state.loggedIn = false;
      state.token = null;

      if (!state.rememberMe) {
        localStorage.removeItem("rememberMe");
        localStorage.removeItem("username");
      } else {
        state.username = localStorage.getItem("username");
      }

      localStorage.removeItem("roles");
      localStorage.removeItem("loggedIn");
    },
    refreshToken(state, action) {
      //console.log('refreshToken',action.payload);
      const { token } = action.payload;

      state.token = token;
    },
    loadAuthState(state) {
      state.username = localStorage.getItem("username");
      state.roles = localStorage.getItem("roles");
      state.rememberMe = localStorage.getItem("rememberMe");
      state.loggedIn = localStorage.getItem("loggedIn");
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice;
