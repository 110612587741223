import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ProSidebarProvider } from "react-pro-sidebar";

import "./index.css";
import App from "./App";
import store from "./store";
import ModalAlert from "./components/UI/Modal/ModalAlert";

import * as bootstrap from 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Icon } from '@iconify/react';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ModalAlert />
      <ProSidebarProvider>
        <App />
      </ProSidebarProvider>
    </BrowserRouter>
  </Provider>
);
