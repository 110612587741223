import React from 'react'
import { FormularioAuditoria } from '../../components/UI/Auditoria/FormularioAuditoria'
import { useSelector } from 'react-redux'
import { BtnRegresar } from '../../components/UI/Botones/BtnRegresar'
import MainLayout from '../../layout/MainLayout'
import { PATH_AUDITORIAS, PATH_STOCK } from '../../util/ConstantesRouter'

export const EditarAuditoria = () => {
  //const navigate = useNavigate();    
  const pathReturn = PATH_STOCK + '/' + PATH_AUDITORIAS;
  
  const {auditoriaSeleccionada} = useSelector(state => state.auditorias);

  return (
    <MainLayout>
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Editar Auditoría
      </h2>     
      <BtnRegresar path={pathReturn}/>
      <FormularioAuditoria auditoria={auditoriaSeleccionada}/> 
    </MainLayout>
  )
}
