import { createSlice } from '@reduxjs/toolkit';

export const farmaciasSlice = createSlice({
   name: 'farmacias',
   initialState: {    
     farmacias: [],
     isLoading: false,
     isLoaded : false,
   },
   reducers: {
       startLoadingFarmacias: (state, /* action */ ) => {
         state.isLoading= true;
         state.isLoaded= false;
        },
        
        setFarmacias: (state,action) => {
           state.isLoading= false;
           state.isLoaded= true;
           state.farmacias = action.payload.farmacias;
       }
   }
});


// Action creators are generated for each case reducer function
export const { setFarmacias } = farmaciasSlice.actions;