import React from 'react'

export const ProductoItemReglonDetFarmacias = ({detalle}) => {

    return (
        detalle.map((det, index)=>
                <div className="d-flex" key={index}>
                    <div className="w-75 small text-black-50">{det.farmacia}</div>
                    <div className="flex-shrink-1 fw-normal small text-black-50">{det.cantidad}</div>         
                </div>    
            )        
    )
}
