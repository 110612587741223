import React from 'react'
import { useSelector } from 'react-redux'
import { CardProductoAuditoriaOpciones } from '../Cards/Auditoria/CardProductoAuditoriaOpciones'
import { FormularioProductoAuditado } from './FormularioProductoAuditado'

export const InventarioAuditoria = () => {
    const {productoAuditadoActivo} = useSelector(state => state.auditorias);
    
  return (    
        <div className="grid grid-cols-1 gap-1 sm:grid-cols-5 my-2">
            <div className=" col-span-5 sm:col-span-2">
                <CardProductoAuditoriaOpciones />
            </div>            
            <div className="col-span-5 sm:col-span-3 overflow-y-auto h-96">
                {!!productoAuditadoActivo 
                    ? <FormularioProductoAuditado />
                    : ''
                }
                
            </div>
        </div> 

  )
}
