import { createSlice } from '@reduxjs/toolkit';

export const laboratoriosSlice = createSlice({
   name: 'laboratorios',
   initialState: {    
    laboratorios: [],
    isLoading: false,
    isLoaded : false,
  },
  reducers: {
      startLoadingLaboratorios: (state, /* action */ ) => {
        state.isLoading= true;
        state.isLoaded= false;
      },
      
      setLaboratorios: (state,action) => {
        state.isLoading= false;
        state.isLoaded= true;
        state.laboratorios = action.payload.laboratorios;
      }
  }
});


// Action creators are generated for each case reducer function
export const { setLaboratorios } = laboratoriosSlice.actions;