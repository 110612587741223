import { createSlice } from "@reduxjs/toolkit";

const stockSlice = createSlice({
  name: "stock",
  initialState: {},
  reducers: {},
});

export const stockActions = stockSlice.actions;

export default stockSlice;
