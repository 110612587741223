import React from 'react'
import { useSelector } from 'react-redux';
import { FormularioProductoAuditado } from '../../components/UI/Auditoria/FormularioProductoAuditado';
import { BtnRegresar } from '../../components/UI/Botones/BtnRegresar'
import MainLayout from '../../layout/MainLayout'
import { PATH_AUDITORIAS, PATH_STOCK } from '../../util/ConstantesRouter';

export const EditarProductoAuditado = () => {
       
    const pathReturn = PATH_STOCK + '/' + PATH_AUDITORIAS;

    const {auditoriaItemSeleccionado} = useSelector(state => state.auditorias);

    return (
        <MainLayout>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Inventario Auditado
          </h2>
          <hr />      
          <BtnRegresar path={pathReturn}/>
          <FormularioProductoAuditado productoAuditado={auditoriaItemSeleccionado}/>
        </MainLayout>
      )
}
