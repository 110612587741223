import { useDispatch } from "react-redux";

import { getNewToken } from "../api/auth";
import { authActions } from "../store/authSlice";

const useRefreshToken = () => {
  const dispatch = useDispatch();

  const refresh = async () => {
    const request = await getNewToken();

    try {
      dispatch(authActions.refreshToken({ token: request.data.token }));
      return request.data.token;
    } catch (err) {
      console.log(err);
    }
  };

  return refresh;
};

export default useRefreshToken;
