import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment'
import { setActiveCompra } from '../../../store/compras';

export const ComprasItem = (
        {descripcion,cantidadDiasParaStock,fechaComprasVentasDesde,fechaComprasVentasHasta,fechaFin
        ,fechaInicio,id,laboratorio,productos}) => {

    const dispatch = useDispatch();

    const {activeCompra} = useSelector(state => state.compras);

    const compraResaltada = (!!activeCompra && activeCompra.id=== id) 
                                ? activeCompra.id ? 'text-bg-success' : 'border border-danger border-5 rounded-5'
                                : '';

    const handleClick = () =>{             
        dispatch(setActiveCompra({descripcion,cantidadDiasParaStock,fechaComprasVentasDesde,fechaComprasVentasHasta,fechaFin,fechaInicio,id,laboratorio,productos}))
    }

    const formatDescription = useMemo(() => {
        return descripcion.length >= 15
            ? `${descripcion.substring(0,15)} ...`
            : descripcion
    } , [descripcion]);


  return (    
        <div className={`card ${compraResaltada} border-secondary m-1 `} onClick={handleClick}>
            <div className="card-header text-truncate">{descripcion}</div>
            <div className="card-body">
                <h5 className="card-title">{laboratorio.nombre}</h5>
                <p className="card-text">#Productos : {productos.length}</p>
                <p className="card-text">Inicio : {moment(fechaInicio).format('DD-MM-YYYY')}</p>
                {fechaFin !== null
                    ? <span className="text-warning bg-dark">Cerrada : {moment(fechaFin).format('DD-MM-YYYY')}</span>
                    : ''
                }    
            </div>
        </div>

  )
}
