export const ERRORES_ERR_NETWORK = "ERR_NETWORK";
export const ERRORES_ERR_BAD_REQUEST = "ERR_BAD_REQUEST";

const getErrorMessage = ({ error }) => {
  let mensaje = {};
  if (error?.response?.data) {
    mensaje = {
      titulo: error.response.data.statusText,
      mensaje: error.response.data.message,
    };
  } else {
    switch (error?.code) {
      case ERRORES_ERR_NETWORK:
        mensaje = {
          titulo: "Error de red",
          mensaje:
            "Hubo un error de comunicaciones y no se pudo enviar su solicitud. Vuelva a intentarlo en unos minutos.",
        };
        break;
      case ERRORES_ERR_BAD_REQUEST:
        mensaje = {
          titulo: "Prohibido",
          mensaje:
            "El acceso a este recurso ha sido rechazado por el servidor.",
        };
        break;
      default:
        mensaje = {
          titulo: error.code,
          mensaje: error.message,
        };
        break;
    }
  }

  return mensaje;
};

const getCustomErrorMessage = ({ titulo, mensaje }) => {
  return { titulo, mensaje };
};

export { getErrorMessage, getCustomErrorMessage };
