import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import Button from "@inovua/reactdatagrid-community/packages/Button";

import moment from "moment";

import usePrivateApi from "../../../hooks/usePrivateApi";
import {
  saveProductos,
  setActiveProducto,
  setThereIsAProductoUpdate,
} from "../../../store/compras";
import Swal from "sweetalert2";
import { getTotalesCompraAceByIdApi } from "../../../api";
import { ComprasTotales } from "./ComprasTotales";

const col_si_no = [
  { id: "S", label: "Si" },
  { id: "N", label: "No" },
];

const filterValue = [
  {
    name: "nombre",
    operator: "porNombre&CodBarra",
    type: "porProductoYCodBarra",
    value: "",
  },
  {
    name: "cantidadPaquetesComprasSugerido",
    operator: "gte",
    type: "number",
    value: 1,
  },
  //{ name: 'farmaciasSinStock', operator: 'startsWith', type: 'string', value: 'S' },
  { name: "farmaciasSinStock", operator: "eq", type: "select", value: "N" },
];

const filterTypes = Object.assign({}, ReactDataGrid.defaultProps.filterTypes, {
  porProductoYCodBarra: {
    name: "nombre",
    operators: [
      {
        name: "porNombre&CodBarra",
        fn: ({ value, filterValue, data }) => {
          if (filterValue == null || filterValue === "") {
            return true;
          }
          return (
            data.producto.nombre
              .trim()
              .toUpperCase()
              .includes(filterValue.trim().toUpperCase()) ||
            data.producto.codigoBarras.trim().includes(filterValue.trim())
          );
        },
      },
    ],
  },
});

const columns = [
  {
    name: "idCompraAce",
    header: "IdCompraAce",
    defaultVisible: false,
    editable: false,
    defaultWidth: 30,
    defaultFlex: 2,
  },
  {
    name: "id",
    header: "IdProducto",
    defaultVisible: false,
    editable: false,
    defaultWidth: 30,
    defaultFlex: 2,
  },

  {
    id: "nombre",
    header: "Producto",
    editable: false,
    defaultWidth: 30,
    defaultFlex: 1,
    render: ({ data }) => data.producto.nombre,
  },

  {
    id: "presentacion",
    header: "Presentacion",
    editable: false,
    defaultWidth: 30,
    defaultFlex: 1,
    render: ({ data }) => data.producto.presentacion,
  },

  {
    id: "codigoBarras",
    header: "Cod.Barras",
    defaultVisible: false,
    editable: false,
    defaultWidth: 30,
    defaultFlex: 1,
    render: ({ data }) => data.producto.codigoBarras,
  },

  {
    id: "laboratorio",
    header: "Laboratorio",
    defaultVisible: false,
    editable: false,
    defaultWidth: 30,
    defaultFlex: 1,
    render: ({ data }) => data.producto.laboratorio.nombre,
  },

  {
    name: "cantidadPaquetesComprasSugerido",
    header: "# Paq. Compras Suger.",
    editable: false,
    defaultWidth: 30,
    defaultFlex: 1,
    type: "number",
    filterEditor: NumberFilter,
  },

  {
    name: "cantidadPaquetesVentasPeriodo",
    header: "# Paq. Vtas Periodo",
    defaultVisible: false,
    editable: false,
    defaultWidth: 30,
    defaultFlex: 1,
  },
  {
    name: "cantidadPaquetesComprasPeriodo",
    header: "# Paq. Compras Periodo",
    defaultVisible: false,
    editable: false,
    defaultWidth: 30,
    defaultFlex: 1,
  },
  {
    name: "fechaStockFarmacia",
    header: "F. Stock",
    defaultVisible: false,
    editable: false,
    defaultWidth: 30,
    defaultFlex: 1,
  },
  {
    name: "cantidadPaquetesStockFarmacia",
    header: "#Paq.Stock Farm.",
    defaultVisible: false,
    editable: false,
    defaultWidth: 30,
    defaultFlex: 1,
  },
  {
    name: "fechaStockAce",
    header: "F. Stock ACE",
    defaultVisible: false,
    editable: false,
    defaultWidth: 30,
    defaultFlex: 1,
  },
  {
    name: "cantidadPaquetesStockAce",
    header: "#Paq.Stock ACE",
    defaultVisible: false,
    editable: false,
    defaultWidth: 30,
    defaultFlex: 1,
  },
  {
    name: "fechaRegistracion",
    header: "F.Registracion",
    defaultVisible: false,
    defaultWidth: 30,
    defaultFlex: 1,
  },
  {
    name: "valueUpdated",
    header: "actualizado",
    defaultVisible: false,
    defaultWidth: 1,
    defaultFlex: 1,
  },

  /*{ name: 'farmaciasSinStock', header: 'Farm.S/Stock',  defaultWidth:30, defaultFlex: 1
                ,render: ({ data }) => data.farmaciasSinStock},
        */

  {
    name: "farmaciasSinStock",
    header: "Farm.S/Stock",
    defaultWidth: 30,
    defaultFlex: 1,
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: "Todos",
      dataSource: col_si_no,
    },
    render: ({ data }) => (data.farmaciasSinStock == "S" ? "S" : "N"),
  },

  {
    name: "cantidadPaquetesCompra",
    header: "# Paq. Compra",
    defaultWidth: 30,
    defaultFlex: 1,
  },
  {
    name: "precioVentaPaquete",
    header: "$ Vta.Paq",
    defaultWidth: 30,
    defaultFlex: 1,
  },
  {
    name: "descuentoExtra",
    header: "% Dto.Extra",
    defaultWidth: 30,
    defaultFlex: 1,
  },
  {
    name: "margenSobreVenta",
    header: "% Margen Vta",
    defaultWidth: 30,
    defaultFlex: 1,
  },
];

const gridStyle = { minHeight: 550 };

function currencyFormat(num = 0) {
  return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export const ComprasProductos = () => {
  const dispatch = useDispatch();
  const { api } = usePrivateApi();

  const [gridRef, setGridRef] = useState(null);

  const { activeCompra, productos, thereIsAProductoUpdated } = useSelector(
    (state) => state.compras
  );

  const [dataSource, setDataSource] = useState(productos);

  const [totalesCompra, setTotalesCompra] = useState({
    cantidadProductos: 0,
    cantidadPaquetes: 0,
    totalCompra: 0.0,
  });

  const handleClickGuardar = () => {
    const productosToUpdate = dataSource
      .filter((prod) => prod.valueUpdated === "S")
      .map((prod) => ({
        id: prod.id,
        cantidadPaquetesCompra: prod.cantidadPaquetesCompra,
        cantidadPaquetesCompra: prod.cantidadPaquetesCompra,
        precioVentaPaquete: prod.precioVentaPaquete,
        margenSobreVenta: prod.margenSobreVenta,
        descuentoExtra: prod.descuentoExtra,
        fechaRegistracion: moment().valueOf(),
      }));

    Swal.fire({
      title: "¿Guardar cambios sobre la compra?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Sí, guardar los cambios!",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return dispatch(saveProductos({ api }, productosToUpdate))
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .catch((error) => {
            Swal.showValidationMessage(`Requerimiento Fallido: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `${result.value}`,
        });
      }
    });
  };

  const onEditComplete = useCallback(
    ({ data, columnId, value }) => {
      //console.log({data});
      //verifico que haya realizado alguna modificacion
      if (data[columnId] === value) return;

      //verifico que sea un numero válido
      let result = /^[-+]?([0-9]*\.[0-9]+|[0-9]+)$/.test(value) || value === "";
      if (!result) return;

      let ahora = moment().format("DD-MM-YYYY");
      //Actualizo los valores de la table
      let newData = {
        ...data,
        [columnId]: value,
        fechaRegistracion: ahora,
        valueUpdated: "S",
      };
      let newDataSource = dataSource.map((origin) => {
        if (origin.id === data.id) return newData;
        else return origin;
      });
      setDataSource(newDataSource);

      dispatch(setThereIsAProductoUpdate());
    },
    [dataSource]
  );

  useEffect(() => {
    setDataSource(productos);
    const getTotales = async () => {
      const totales = await getTotalesCompraAceByIdApi(
        { api },
        activeCompra.id
      );
      //console.log('totales',{totales});
      const totalInCurrency = currencyFormat(totales.data.totalCompra);
      setTotalesCompra({ ...totales.data, totalCompra: totalInCurrency });
    };
    getTotales();
    return () => {
      setDataSource([]);
    };
  }, [productos]);

  const onSelectionChange = useCallback(({ data }) => {
    dispatch(setActiveProducto(data));
  }, []);

  const downloadBlob = (blob, fileName = "grid-data.csv") => {
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.position = "absolute";
    link.style.visibility = "hidden";

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const SEPARATOR = ",";

  const exportCSV = () => {
    // https://reactdatagrid.io/docs/miscellaneous#lazy-edit-with-editors-being-rendered-in-cell-contents.
    const columns = [
      { name: "nombre", id: "nombre" },
      { name: "codigoBarras", id: "codigoBarras" },
      { name: "cantidadPaquetesCompra", id: "cantidadPaquetesCompra" },
    ];
    const header = columns.map((c) => c.name).join(SEPARATOR);
    const dataFlat = gridRef.current.dataSource
      .filter((data) => data.cantidadPaquetesCompra > 0)
      .map((data) => ({
        nombre: data.producto.nombre,
        codigoBarras: data.producto.codigoBarras,
        cantidadPaquetesCompra: data.cantidadPaquetesCompra,
      }));

    const rows = dataFlat.map((data) =>
      columns.map((c) => data[c.id]).join(SEPARATOR)
    );

    const contents = [header].concat(rows).join("\n");

    const blob = new Blob([contents], { type: "text/csv;charset=utf-8;" });

    downloadBlob(blob, "compras.csv");
  };

  const exportCSV_farmaciasSinStock = () => {
    // https://reactdatagrid.io/docs/miscellaneous#lazy-edit-with-editors-being-rendered-in-cell-contents.
    const columns = [
      { name: "nombre", id: "nombre" },
      { name: "codigoBarras", id: "codigoBarras" },
      { name: "cantidadPaquetesCompra", id: "cantidadPaquetesCompra" },
    ];
    const header = columns.map((c) => c.name).join(SEPARATOR);
    const dataFlat = gridRef.current.dataSource
      .filter((data) => data.farmaciasSinStock === "S")
      .map((data) => ({
        nombre: data.producto.nombre,
        codigoBarras: data.producto.codigoBarras,
        cantidadPaquetesCompra: data.cantidadPaquetesCompra,
      }));

    const rows = dataFlat.map((data) =>
      columns.map((c) => data[c.id]).join(SEPARATOR)
    );

    const contents = [header].concat(rows).join("\n");

    const blob = new Blob([contents], { type: "text/csv;charset=utf-8;" });

    downloadBlob(blob, "rotacion.csv");
  };

  return (
    <>
      <div className="text-success">
        {" "}
        <hr />{" "}
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-2">
            {thereIsAProductoUpdated ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleClickGuardar}
              >
                Guardar
              </button>
            ) : (
              ""
            )}
          </div>
          <div className="col-12 col-lg-10">
            <ComprasTotales totalesCompra={totalesCompra} />
          </div>
        </div>
      </div>

      <div className="text-success">
        {" "}
        <hr />{" "}
      </div>

      <ReactDataGrid
        idProperty="id"
        handle={setGridRef}
        columns={columns}
        editable={true}
        onEditComplete={onEditComplete}
        dataSource={dataSource}
        style={gridStyle}
        defaultFilterValue={filterValue}
        filterTypes={filterTypes}
        enableColumnAutosize={true}
        pagination
        defaultLimit={10}
        enableSelection={true}
        onSelectionChange={onSelectionChange}
      />

      <Button style={{ marginTop: 20 }} onClick={exportCSV}>
        Export CSV
      </Button>

      <Button style={{ marginTop: 20 }} onClick={exportCSV_farmaciasSinStock}>
        Export CSV Farm.S/Stock
      </Button>
    </>
  );
};
