//Recupera todas los laboratorios
export const getLaboratoriosAPI = async ({ api }) => {        
  return await api.get("/laboratorios/");            
};

export const getLaboratorios = async ({ api }) => {
  return await api.get("/laboratorios/");
};

export const getStockLaboratorio = ({ api, idLaboratorio }) => {
  return api.get("/laboratorios/" + idLaboratorio + "/stock/");
};
