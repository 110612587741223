import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    nombre: "",
    apellido: "",
    email: "",
    rol: "",
    lastLogin: null,
    id:"",
  },
  reducers: {
    updateData(state, action) {
      const { nombre, apellido, rol, email, id } = action.payload;

      state.nombre = nombre;
      localStorage.setItem("nombre", nombre);

      state.apellido = apellido;
      localStorage.setItem("apellido", apellido);

      state.email = email;
      localStorage.setItem("email", email);

      state.rol = rol;
      localStorage.setItem("rol", rol);
      
      state.id = id;
      localStorage.setItem("id", id);
      
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice;
