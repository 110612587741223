import { getFarmaciasAPI } from "../../api/farmacias";
import { setFarmacias} from "./farmaciasSlice"


export const startLoadingFarmacias = (api) => {
    return async (dispatch,getSate) => {          
        const data =  await getFarmaciasAPI(api);               
        dispatch(setFarmacias({farmacias: data.data}));
    }
}


export const getFarmacias = ( api ) => {
    return async(dispatch, getState) => {
        //dispatch(startLoadingFarmacias());
        const data =  await api.get("/farmacias/");        
        dispatch(setFarmacias({farmacias: data.data}));
    }
}