import axios from "axios";

export const BASE_URL =
  process.env.REACT_APP_API_PROTOCOL +
  "://" +
  process.env.REACT_APP_API_HOST +
  ":" +
  process.env.REACT_APP_API_PORT;

export const apiPrivada = axios.create({
  baseURL: BASE_URL + "/api",
  headers: { "Content-Type": "application/json" },
});

export const apiPublica = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
