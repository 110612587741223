import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

/*formato de la auditoria activa
    auditoriaActiva:{
        descripcion,
        farmacia,
        laboratorio,
        fechaFin,
        fechaInicio,
        id,
        productos:[],
        usuario
    }
*/

export const auditoriasSlice = createSlice({
   name: 'auditoria',
   initialState: {    
      isLoadingAuditorias: false,
      isSaving: false,      
      isSavingProductoAuditado: false,      
      messageSaved:'',
      messageSavedProductoAuditado:'',
      messageLoadingAuditoria:'',
      auditorias: [],      
      auditoriaActiva:null, 
      productoAuditadoActivo:null, 
      condicionAuditoria:'opened', 
      condicionStartAuditorias:moment().subtract(1,'day').valueOf(),
      condicionEndAuditorias:moment().valueOf(),
      condicionFarmaciaAuditoria:null,
   },

   reducers: {   
        //Seta la condicion de las auditorias //opened/closed/all
        setConditionAuditoria:(state,action) =>{            
            state.condicionAuditoria=action.payload;
        },

        //Seta la condicion de las auditorias //opened/closed/all
        setConditionStartEndAuditoria:(state,action) =>{            
            state.condicionStartAuditorias=action.payload.startAuditorias;
            state.condicionEndAuditorias=action.payload.endAuditorias;
        },

        //Seta la condicion de la farmacia seleccionada
        setConditionFarmaciaAuditoria:(state,action) =>{
            //console.log(action.payload);
            state.condicionFarmaciaAuditoria=action.payload;
        },

        //Indicación que se está generando una nueva auditoría
        savingNewAuditoria:(state)=>{
            state.isSaving=true;
        },

        //Crea un nuevo espacio para ser cargada una auditoría
        addNewAuditoria:(state,action)=>{
            //verifico que no exista una auditoria en progreso
            if(state.auditorias.find((auditoria)=>auditoria.id === null)  !== undefined){
                console.log('ya existe una auditoria en progreso');
                return;
            }
            state.isSaving=true;
            const newAuditoria = {
                id:null,//id,
                farmacia:{id:'',nombreCorto:""},
                laboratorio:{id:'', nombre:""},            
                descripcion:'',                
                usuario:{apellido:'',nombre:''},
                productos:[]
            };
            state.auditorias.push(newAuditoria);
            state.auditoriaActiva=newAuditoria;
            state.messageSaved='';    
            state.isSaving=false;
        },

        //Indicación que se está finalizando la auditoría
        setEndingAuditoria:(state)=>{
            state.isSaving=true;
            state.messageSaved='';
        },
        //Finaliza en el store la auditoría indicada             
        endAuditoria:(state,action)=>{
            state.isSaving=false;  
            state.auditoriaActiva=null;          
            state.auditorias = state.auditorias.filter((auditoria)=>auditoria.id !== action.payload.id);
            state.messageSaved=`${action.payload.descripcion}, finalizada correctamente`;
        },

        //Indicación que se está eliminando la auditoría
        setDeletingAuditoria:(state)=>{
            state.isSaving=true;
            state.messageSaved='';
        },
        //Elimna del store el nuevo espacio que había sido creado cargar una auditoría        
        deleteAuditoria:(state,action)=>{
            state.auditorias = state.auditorias.filter((auditoria)=>auditoria.id !== action.payload.id);
            state.isSaving=false;
            state.auditoriaActiva=null;
            state.messageSaved=`${action.payload.descripcion}, eliminada correctamente`;
        },

        //Estable la auditoría activa
        setActiveAuditoria:(state,action)=>{
            state.auditoriaActiva=action.payload;
            state.messageSaved='';
            state.productoAuditadoActivo=null;
        },

        //Indicacion que hubo algún fallo en la carga de auditorias
        setFailLoadingAuditoria:(state,action)=>{
            state.isLoadingAuditorias=false;
            state.auditorias=[];
            state.auditoriaActiva=null;
            //console.log('setFailLoadingAuditoria',{action});
            state.messageLoadingAuditoria=`Fallo durante la carga de auditorias. ${action.payload.descripcion}`;
        },        

        //Indicacion qe se están cargando las auditorias
        setLoadingAuditoria:(state,action)=>{
            state.isLoadingAuditorias=true;
            state.auditorias=[];
            state.auditoriaActiva=null;
            state.messageLoadingAuditoria=``;
        },        
        //Cargar todas las auditoría al store // load
        setAuditorias:(state,action)=>{
            state.auditorias = action.payload;
            state.auditoriaActiva=null;
            state.isLoadingAuditorias=false;
            state.messageLoadingAuditoria=``;
        },

        //Indicación que se está cargando la auditoría
        setSavingAuditoria:(state)=>{
            state.isSaving=true;
            state.messageSaved='';
        },
        //Actualización de una auditoría en el store
        auditoriaUpdated:(state,action)=>{
            state.isSaving=false;  
            state.auditoriaActiva=action.payload          
            state.auditorias = state.auditorias.map((auditoria)=>{
                if((auditoria.id === action.payload.id) || (auditoria.id===null)){
                    return action.payload;
                }
                return auditoria;
            });
            state.messageSaved=`${action.payload.descripcion}, actualizada correctamente`;
        },          

        //Estable el producto auditado activo
        setActiveProductoAuditado:(state,action)=>{
            state.productoAuditadoActivo=action.payload;
            state.messageSavedProductoAuditado='';
        },
        //Indicación que se está cargando la auditoría
        setSavingProductoAuditado:(state)=>{
            state.isSavingProductoAuditado=true;
            state.messageSavedProductoAuditado='';
        },
        //Actualización de un producto auditado en el store
        productoAuditadoUpdated:(state,action)=>{
            state.isSavingProductoAuditado=false;  
            //state.productoAuditadoActivo=action.payload;
            state.productoAuditadoActivo=null;
            state.auditoriaActiva.productos = state.auditoriaActiva.productos.map(prod =>{
                if((prod.id === action.payload.id)){
                    return action.payload;
                }
                return prod;
            });  
            state.auditorias = state.auditorias.map((auditoria)=>{
                if((auditoria.id === state.auditoriaActiva.id)){
                    return state.auditoriaActiva;
                }
                return auditoria;
            });
            console.log(action.payload);
            state.messageSavedProductoAuditado=`${action.payload.descripcion}, actualizado correctamente`;
        }, 
   }
});


// Action creators are generated for each case reducer function
export const { 
    setConditionAuditoria,
    setConditionStartEndAuditoria,
    setConditionFarmaciaAuditoria,    
    addNewAuditoria,
    setEndingAuditoria,
    endAuditoria,
    setDeletingAuditoria,
    deleteAuditoria,
    setActiveAuditoria,
    setFailLoadingAuditoria,
    setLoadingAuditoria,
    setAuditorias,
    setSavingAuditoria,
    auditoriaUpdated,
    savingNewAuditoria,
    setActiveProductoAuditado,
    setSavingProductoAuditado,  
    productoAuditadoUpdated,  
    } = auditoriasSlice.actions;