import React, {  useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import Datepicker from 'react-tailwindcss-datepicker';
import moment from 'moment';

import usePrivateApi from '../../../../hooks/usePrivateApi';
import { setConditionAuditoria, setConditionFarmaciaAuditoria, setConditionStartEndAuditoria, startLoadingAuditorias } from '../../../../store/auditorias';

export const CardAuditoriaOpciones = () => {

    const { api } = usePrivateApi();    
    const dispatch = useDispatch();  
    
    const { condicionAuditoria } = useSelector(state => state.auditorias);
    const {farmacias=[]} = useSelector(state => state.farmacias);

    const onSelect =(e)=>{
        dispatch(setConditionAuditoria(e.target.value));
        dispatch(startLoadingAuditorias({api}))
    }

    const [value, setValue] = useState({ 
        startDate: moment().subtract(1.5, 'months'),
        endDate: moment(),
    }); 
        
    const handleValueChange = (newValue) => {        
        setValue(newValue); 
        dispatch(setConditionStartEndAuditoria({               
            startAuditorias:moment(newValue.startDate).valueOf(),
            endAuditorias:moment(newValue.endDate).valueOf(),
        }));
        dispatch(startLoadingAuditorias({api}));
    } 

    const handleChangeFarmacia = (e) =>{        
        dispatch(setConditionFarmaciaAuditoria(e===null ? e : e.value));
        dispatch(startLoadingAuditorias({api}));
    }


    const checkedOpened =(condicionAuditoria === 'opened') ? 'checked':''; 
    const checkedClosed =(condicionAuditoria === 'closed') ? 'checked':''; 
    const checkedAll    =(condicionAuditoria === 'all')    ? 'checked':''; 
    
  return (        
    <>
        <h3 className="mb-4 font-semibold text-gray-900 dark:text-white">Estado de las Auditorias</h3>
        <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                <div className="flex items-center pl-3">
                    <input checked={checkedOpened} onChange={onSelect} id="horizontal-list-radio-license" type="radio" value="opened" name="list-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                    <label htmlFor="horizontal-list-radio-license" className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Abiertas</label>
                </div>
            </li>
            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                <div className="flex items-center pl-3">
                    <input checked={checkedClosed}   onChange={onSelect} id="horizontal-list-radio-id" type="radio" value="closed" name="list-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                    <label htmlFor="horizontal-list-radio-id" className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Cerradas</label>
                </div>
            </li>
            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                <div className="flex items-center pl-3">
                <input checked={checkedAll} onChange={onSelect} id="horizontal-list-radio-millitary" type="radio" value="all" name="list-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                    <label htmlFor="horizontal-list-radio-millitary" className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Todas</label>
                </div>
            </li>

            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                {/* <div className="flex items-center pl-3"> */}
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    //defaultValue={farmacias[0]}                                        
                    isClearable={true}                    
                    isSearchable={true}
                    name="farmacia"                    
                    options={farmacias.map(({id,nombre})=>({value:id,label:nombre}))}
                    onChange={handleChangeFarmacia}
                />                    
                {/* </div> */}
            </li>

            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                <div className="flex items-center pl-3">

                {/* https://react-tailwindcss-datepicker.vercel.app/props */}
                <Datepicker 
                    useRange={false}
                    placeholder={"Mi período"} 
                    value={value} 
                    onChange={handleValueChange} 
                    showShortcuts={false} 
                    showFooter={false} 
                    displayFormat={"DD/MM/YYYY"} 
                    minDate={new Date("2023-01-01")} 
                    maxDate={new Date("2033-01-30")} 
                /> 
                
                
                </div>
            </li>
            
        </ul>
    </>
  )
};  

