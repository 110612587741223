// Constantes para paths de STOCK
export const PATH_STOCK = "/stock";
export const PATH_PRODUCTOS = "productos";
export const PATH_DROGAS = "drogas";
export const PATH_ACC_TERAPEUTICAS = "accionesTerapeuticas";
export const PATH_LABORATORIOS = "laboratorios";
export const PATH_AUDITORIAS = "auditorias";
export const PATH_COMPRAS = "compras";

export const PATH_AUDITORIAS_ALTA = "auditorias/alta";
export const PATH_AUDITORIAS_EDITAR = "auditorias/editar/:id";
export const PATH_AUDITORIAS_EDITAR_PRODUCTO_AUDITADO =
  "auditorias/productosAuditados/:auditoriaLaboratorio/:id";

export const PATH_ESTADISTICAS = "/estadisticas";

export const PATH_SISTEMA = "/sistema";

export const PATH_LOGIN = "/login";
export const PATH_LOGOUT = "/logout";
export const PATH_UNAUTHORIZED = "/unauthorized";
