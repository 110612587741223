import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import useRefreshToken from "./useRefreshToken";
import { apiPrivada } from "../api/axios";
import { errorActions } from "../store/errorSlice";
import {
  ERRORES_ERR_BAD_REQUEST,
  getCustomErrorMessage,
} from "../util/ErrorsHandler";

const usePrivateApi = () => {
  const api = apiPrivada;

  const refresh = useRefreshToken();

  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  useEffect(() => {
    const requestIntercept = api.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const responseIntercept = api.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (
          (error?.response?.status === 403 ||
            error?.response?.status === 500) &&
          !prevRequest?.sent
        ) {
          prevRequest.sent = true;
          try {
            const newAccessToken = await refresh();

            return await api({
              ...prevRequest,
              headers: {
                ...prevRequest.headers,
                Authorization: `Bearer ${newAccessToken}`,
              },
              sent: true,
            });
          } catch (error) {
            //Si el error code es 403, 99% de chances de que caducó la cookie que se usa para el refresh
            if (error.code === ERRORES_ERR_BAD_REQUEST) {
              dispatch(
                errorActions.showModalError(
                  getCustomErrorMessage({
                    titulo: "La sesión expiró",
                    mensaje:
                      "La sesión de usuario expiró. Es necesario que vuelva a ingresar nuevamente a la aplicación.",
                  })
                )
              );
              navigate("/logout");
            }
            return Promise.reject(error);
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      api.interceptors.request.eject(requestIntercept);
      api.interceptors.response.eject(responseIntercept);
    };
  }, [token, api, refresh, dispatch, navigate]);

  return { api };
};

export default usePrivateApi;
