import { Navigate, Outlet, useLocation } from "react-router-dom";

const LoginRequired = () => {
  const username = localStorage.getItem("username");
  const loggedIn = localStorage.getItem("loggedIn");

  const location = useLocation();

  return username && loggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default LoginRequired;
