import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setActiveAuditoria, startDeleteAuditoria, startEndAuditoria } from '../../../../store/auditorias';
import { Dropdown } from 'flowbite-react';
import moment from 'moment';
import Swal from 'sweetalert2';
import usePrivateApi from '../../../../hooks/usePrivateApi';
import { useExport } from '../../../../hooks/useExport';

export const CardAuditoriaItem = ({descripcion,farmacia,laboratorio,fechaFin,fechaInicio,id,productos,usuario}) => {

  const { api }  = usePrivateApi();
  const dispatch = useDispatch();

  const {onClickExportXLS}   = useExport();
  
  const rolesLogged = localStorage.getItem("roles") !== null ? localStorage.getItem("roles") : [];

  const { isSaving, auditoriaActiva} = useSelector(state => state.auditorias);  

  //Determina si tiene que ponerle el borde de otro color
  const resaltarCard = (auditoriaActiva !== null && id===auditoriaActiva.id);
  const resaltarAuditoriaInProgress = (auditoriaActiva !== null && id===null);
  const modeBorderAuditoria = 
        (! resaltarCard) 
            ? 'border-2 border-gray-100'
            : (resaltarAuditoriaInProgress) 
                ? 'border-4 border-red-800 border-dashed'
                : 'border-4 border-red-800';

  const onClickAuditoria = ()=>{   
    dispatch (setActiveAuditoria({descripcion,farmacia,laboratorio,fechaFin,fechaInicio,id,productos,usuario}));
  }


  const onClickEliminarAuditoria = ()=>{
    Swal.fire({
      title: '¿Eliminamos la auditoría?',  
      text: "No se puede revertir esta acción!",
      icon: 'warning',    
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sí, eliminarla!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return dispatch(startDeleteAuditoria({api}))
          .then(response => {                        
            if (!response.ok) {
               throw new Error(response.statusText)
             }
             return response.json();
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Requerimiento Fallido: ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {        
        Swal.fire({
          title: `${result.value}`,          
        })
      }
    })
  }

  const onClickFinalizarAuditoria = ()=>{
    Swal.fire({
      title: '¿Finalizar auditoría?',  
      text: "No se puede revertir esta acción!",
      icon: 'warning',    
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sí, darla por finalizada!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return dispatch(startEndAuditoria({api}))
          .then(response => {                        
            if (!response.ok) {
               throw new Error(response.statusText)
             }
             return response.json();
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Requerimiento Fallido: ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {        
        Swal.fire({
          title: `${result.value}`,          
        })
      }
    })
  }

  const txtLimitado = (texto='',long = 10) => {
    return texto.length > long
        ? ` ${texto.substring(0,long)}...`
        : texto
  }
  const descripcionFormat = useMemo(() =>{
     return txtLimitado(descripcion, 17);        
  } , [descripcion]);

  const farmaciaFormat = useMemo(() =>{
    return txtLimitado(farmacia.nombre, 10);
  } , [farmacia.nombre]);

  const laboratorioFormat = useMemo(() =>{
    return txtLimitado(laboratorio.nombre, 10);
  } , [laboratorio.nombre]);

  const formatToExport = (data) => {
      return data.map((producto) => {
          return {                                
            'Auditoria':auditoriaActiva.descripcion,                                    
            'Farmacia':auditoriaActiva.farmacia.nombre,                                  
            'Laboratorio':auditoriaActiva.laboratorio.nombre,
            'Administrador':auditoriaActiva.usuario.nombreUsuario,
            'Inicio Aud':auditoriaActiva.fechaInicio!== null ? moment(auditoriaActiva.fechaInicio).format('DD-MM-YYYY') : '-',
            'Fin Aud':auditoriaActiva.fechaFin!== null ? moment(auditoriaActiva.fechaFin).format('DD-MM-YYYY') : '-',                                    
            'Producto':producto.producto.nombre,
            'Presentacion':producto.producto.presentacion,
            'Fecha Stock':moment(producto.fechaStock).format('DD-MM-YYYY'),
            '# Paq. Stock':producto.cantidadPaquetesStock,
            '# Fracc. Stock':producto.cantidadFraccionesStock,
            'Fecha Inventario':producto.fechaInventario!== null ? moment(producto.fechaInventario).format('DD-MM-YYYY') : '-',
            '# Paq. Inventario':producto.cantidadPaquetesInventario,
            '# Fracc. Inventario':producto.cantidadFraccionesInventario,                                     
            'Auditor':producto.usuario!== null ? producto.usuario.nombreUsuario : '-',
          }
      })
  }
  
  return (
    <>

      {!isSaving && (
        <div onClick={onClickAuditoria}
            className={`w-full max-w-sm bg-white ${modeBorderAuditoria} rounded-lg shadow dark:bg-gray-800 dark:border-gray-700`}>
            <div className="flex justify-end px-4 pt-4">
            {(rolesLogged.includes('USUARIO_ADMINISTRACION'))
              ?
                <Dropdown  size="xs" pill color="gray" label="...">                
                  <Dropdown.Item onClick={(e) => onClickExportXLS(formatToExport(auditoriaActiva.productos)
                          ,`Auditoria ${auditoriaActiva.descripcion}`
                        )}>                  
                    Reporte
                  </Dropdown.Item>
                  
                  {(fechaFin === null)
                    ?<Dropdown.Item  onClick={onClickFinalizarAuditoria}>
                      Finalizar
                     </Dropdown.Item>
                    :'' 
                  }  
                  {
                  (fechaFin === null)
                    ?<Dropdown.Item onClick={onClickEliminarAuditoria}>
                      Eliminar
                      </Dropdown.Item>              
                    : ''  
                  }  
                </Dropdown>
              : ''  
}  

            </div>
            <div className="flex flex-col items-center pb-10">
                <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">{descripcionFormat}</h5>
                <span className="text-sm text-gray-500 dark:text-gray-400">{farmaciaFormat}</span>
                <span className="text-sm text-gray-500 dark:text-gray-400">{laboratorioFormat}</span>
                <span className="text-sm text-gray-500 dark:text-gray-400">Inicio : {moment(fechaInicio).format('DD-MM-YYYY')}</span>
                {fechaFin !== null
                  ? <span className="text-sm text-gray-500 dark:text-gray-400">Cerrada : {moment(fechaFin).format('DD-MM-YYYY')}</span>
                  : ''
                }                
            </div>
        </div>
      )}

    </>
  )
}
