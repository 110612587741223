import { addAuditoriaAPI, deleteAuditoriaAPI, finalizarAuditoriaAPI, getAuditoriaByIdAPI, getAuditoriasAPI, updateAuditoriaAPI, updateProductoAuditadoAPI } from "../../api/auditorias";
import { addNewAuditoria, auditoriaUpdated, deleteAuditoria, endAuditoria, productoAuditadoUpdated, savingNewAuditoria, setActiveAuditoria, setAuditorias, setDeletingAuditoria, setEndingAuditoria, setFailLoadingAuditoria, setLoadingAuditoria, setSavingAuditoria, setSavingProductoAuditado } from "./";

//Crea un nuevo espacio para ser cargada una auditoría
export const startNewAuditoria= () =>{    
    return async (dispatch, getState) =>{

        dispatch( savingNewAuditoria());

        const newAuditoria = {
            id:null,//id,
            farmacia:{id:null},
            laboratorio:{id:null},            
            descripcion:'',
        }

        //!dispatch
        dispatch (addNewAuditoria( newAuditoria ));
        dispatch (setActiveAuditoria (newAuditoria) );

    }

}

export const startLoadingAuditorias = (api) => {
    return async (dispatch,getState) => {  
        try{
            const {condicionAuditoria,condicionStartAuditorias,condicionEndAuditorias,condicionFarmaciaAuditoria} = getState().auditorias; 
            dispatch(setLoadingAuditoria());
            let auditorias = await getAuditoriasAPI(api,{condicionAuditoria,condicionStartAuditorias,condicionEndAuditorias,condicionFarmaciaAuditoria});        
            dispatch (setAuditorias(auditorias.data));
        }catch (e){
            const message=`Auditorias - Thunks - startLoadingAuditorias - Error ${e}`;
            //console.log(message); 
            dispatch(setFailLoadingAuditoria({descripcion:message}));
        }    
    }
}

export const startSaveAuditoria= (api) =>{    
    return async (dispatch, getState) =>{
        try{
            let auditoriaAux={};
            dispatch(setSavingAuditoria());
            const {auditoriaActiva} = getState().auditorias;                       
            //Actualización en el backEnd
            if (auditoriaActiva.id === null){                                  
                const resp = await addAuditoriaAPI(api,auditoriaActiva);            
                auditoriaAux = await getAuditoriaByIdAPI(api,resp.data);                                                
            }else{            
                await updateAuditoriaAPI(api,auditoriaActiva);                        
                auditoriaAux = await getAuditoriaByIdAPI(api,auditoriaActiva.id);            
            }
            //Actualización en el fronEnd
            dispatch(auditoriaUpdated(auditoriaAux.data));
            return {'ok':true, 'json':()=>('Finalización Exitosa')};
        }catch (e){
            return {'ok':false, statusText:e.message};  
        }            
    }
}    

export const startDeleteAuditoria= (api) =>{    
    return async (dispatch, getState) =>{        
        try{            
            dispatch(setDeletingAuditoria());
            const {auditoriaActiva} = getState().auditorias;                       
            //Actualización en el backEnd                
            await deleteAuditoriaAPI(api,auditoriaActiva.id);        
            //Actualización en el fronEnd            
            dispatch(deleteAuditoria(auditoriaActiva));            
            return {'ok':true, 'json':()=>('Borrado Exitoso')};
        }catch(e){            
            return {'ok':false, statusText:e.message};
        }    
    }
}    

export const startEndAuditoria= (api) =>{    
    return async (dispatch, getState) =>{        
        try{                
            dispatch(setEndingAuditoria());
            const {auditoriaActiva} = getState().auditorias;                       
            //Actualización en el backEnd               
            await finalizarAuditoriaAPI(api,auditoriaActiva);            
            await getAuditoriaByIdAPI(api,auditoriaActiva.id);        
            //Actualización en el fronEnd            
            dispatch(endAuditoria(auditoriaActiva));            
            return {'ok':true, 'json':()=>('Finalización Exitosa')};
        }catch(e){            
            return {'ok':false, statusText:e.message};
        }    
    }
}    

export const startSaveProductoAuditado= (api) =>{    
    return async (dispatch, getState) =>{
        try{            
            dispatch(setSavingProductoAuditado());
            const {productoAuditadoActivo} = getState().auditorias; 
            //Actualización en el backEnd  
            await updateProductoAuditadoAPI(api,{
                "id":productoAuditadoActivo.id,
                "cantidadFraccionesInventario":productoAuditadoActivo.cantidadFraccionesInventario,
                "cantidadPaquetesInventario":productoAuditadoActivo.cantidadPaquetesInventario,    
                "fechaInventario":productoAuditadoActivo.fechaInventario
            });                                                   
            //Actualización en el fronEnd
            dispatch(productoAuditadoUpdated(productoAuditadoActivo));
            return {'ok':true, 'json':()=>('Finalización Exitosa')};
        }catch (e){
            return {'ok':false, statusText:e.message};  
        }            
    }
} 