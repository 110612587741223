import React from 'react'
import { ComprasItem } from './ComprasItem';
import { useSelector } from 'react-redux';

export const ComprasContainer = () => {
  const {compras} = useSelector(state=>state.compras);
  
  return (
    <div className="d-flex justify-content-start flex-wrap"> 
        {compras.map((compra)=><ComprasItem key={compra.id} {...compra}/>)}
    </div>
  )
}
