import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Select from 'react-select';
import Datepicker from 'react-tailwindcss-datepicker';
import moment from 'moment';
import { Icon } from '@iconify/react';

import usePrivateApi from '../../../hooks/usePrivateApi';
import { addNewEmptyCompra, loadCompras, setFilterCompraCondicionCompra, setFilterCompraLaboratorio, setFilterCompraPeriodo } from '../../../store/compras';



    //Se define para poder usar react-select con distribucion flex d-flex
    const styles = {
        container: base => ({
          ...base,
          flex: 1
        })
    }    


export const ComprasFilterOptions = () => {

    const dispatch = useDispatch();
    const { api } = usePrivateApi(); 

    const {filterCompras, isSavingCompra, compras} = useSelector (state => state.compras);
    const {laboratorios=[]} = useSelector (state => state.laboratorios);

    const onClickNewCompra = () => {
        dispatch(addNewEmptyCompra());
    }

    const onSelectCondicionRadio = (e) =>{        
        dispatch(setFilterCompraCondicionCompra(e.target.value));
        dispatch(loadCompras({api}));
    }

    const handleChangeLaboratorio = (e) =>{        
        dispatch(setFilterCompraLaboratorio(e===null ? {"id": 0} : {"id": e.value}));
        dispatch(loadCompras({api}));
    }

    const [value, setValue] = useState({ 
        startDate: moment().subtract(1.5, 'months'),
        endDate: moment(),
    }); 
        
    const handlePeriodoChange = (periodo) => {        
        setValue(periodo); 
        dispatch(setFilterCompraPeriodo({               
            fhIni:moment(periodo.startDate).valueOf(),
            fhFin:moment(periodo.endDate).valueOf(),
        }));
        dispatch(loadCompras({api}));
    } 



  return (
    <>

        <div className="container-fluid mb-4">
            <div className="row d-flex justify-content-between bg-body-tertiary">
                <div className="col-12 col-lg-3 d-flex justify-content-around align-items-center">
                    <span>Condici&oacute;n :</span>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" id="horizontal-list-compras-opened" name="list-radio" 
                            checked={filterCompras.condicionCompraAce === 'opened' ? 'checked' : ''} 
                            onChange={onSelectCondicionRadio}                          
                            value="opened"  />
                        <label className="form-check-label" htmlFor="horizontal-list-compras-opened">Abiertas</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input id="horizontal-list-compras-closed" type="radio" name="list-radio"
                            checked={filterCompras.condicionCompraAce === 'closed' ? 'checked' : ''} 
                            onChange={onSelectCondicionRadio}                          
                            value="closed"  
                            className="form-check-input"/>
                        <label htmlFor="horizontal-list-compras-closed" className="form-check-label">Cerradas</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input id="horizontal-list-compras-all" type="radio" name="list-radio"
                            checked={filterCompras.condicionCompraAce === 'all' ? 'checked' : ''} 
                            onChange={onSelectCondicionRadio}                          
                            value="all"  
                            className="form-check-input"/>
                        <label htmlFor="horizontal-list-compras-all" className="form-check-label">Todas</label>
                    </div>
                </div>
                <div className="col-12 col-lg-3 d-flex justify-content-start align-items-center">
                {/* <div className="col-12 col-lg-3 pt-2"> */}
                    <Select   styles={styles}                     
                        className="basic-single"
                        classNamePrefix="select"
                        //defaultValue={laboratorios[0]}                                        
                        isClearable={true}                    
                        isSearchable={true}
                        name="laboratorio"  
                        placeholder="Laboratorios"                  
                        options={laboratorios.map(({id,nombre})=>({value:id,label:nombre}))}
                        onChange={handleChangeLaboratorio}
                    />
                </div>
                <div className="col-12 col-lg-3 d-flex justify-content-start align-items-center">
                    {/* https://react-tailwindcss-datepicker.vercel.app/props */}
                    <Datepicker 
                            useRange={false}
                            placeholder={"Mi período"} 
                            value={value} 
                            onChange={handlePeriodoChange} 
                            showShortcuts={false} 
                            showFooter={false} 
                            displayFormat={"DD/MM/YYYY"} 
                            minDate={new Date("2022-01-01")} 
                            maxDate={new Date("2033-01-30")} 
                        /> 
                </div>
                <div className="col-12 col-lg-3 d-flex justify-content-end align-items-center">
                    {(compras.every(compra => compra.id !== null))
                        ?
                            <button className="btn btn-outline-success me-md-2" type="button"
                                onClick={onClickNewCompra}
                                disabled={isSavingCompra}>                                                
                                Nueva Compra
                            </button> 
                        : ''    
                    }       
                </div>
            </div>
        </div>
{/*         


        <ul className="mb-4 items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                <div className="flex items-center pl-3">
                    <input id="horizontal-list-compras-opened" type="radio" name="list-radio"
                        checked={filterCompras.condicionCompraAce === 'opened' ? 'checked' : ''} 
                        onChange={onSelectCondicionRadio}                          
                        value="opened"  
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                    <label htmlFor="horizontal-list-compras-opened" className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Abiertas</label>
                </div>
            </li>
            
            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                <div className="flex items-center pl-3">
                    <input id="horizontal-list-compras-closed" type="radio" name="list-radio"
                        checked={filterCompras.condicionCompraAce === 'closed' ? 'checked' : ''} 
                        onChange={onSelectCondicionRadio}                          
                        value="closed"  
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                    <label htmlFor="horizontal-list-compras-closed" className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Cerradas</label>
                </div>
            </li>
            
            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                <div className="flex items-center pl-3">
                    <input id="horizontal-list-compras-all" type="radio" name="list-radio"
                        checked={filterCompras.condicionCompraAce === 'all' ? 'checked' : ''} 
                        onChange={onSelectCondicionRadio}                          
                        value="all"  
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                    <label htmlFor="horizontal-list-compras-all" className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Todas</label>
                </div>
            </li>

            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    //defaultValue={laboratorios[0]}                                        
                    isClearable={true}                    
                    isSearchable={true}
                    name="laboratorio"                    
                    options={laboratorios.map(({id,nombre})=>({value:id,label:nombre}))}
                    onChange={handleChangeLaboratorio}
                />                    
            </li>

            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                <div className="flex items-center pl-3">
                    https://react-tailwindcss-datepicker.vercel.app/props 
                    <Datepicker 
                        useRange={false}
                        placeholder={"Mi período"} 
                        value={value} 
                        onChange={handlePeriodoChange} 
                        showShortcuts={false} 
                        showFooter={false} 
                        displayFormat={"DD/MM/YYYY"} 
                        minDate={new Date("2022-01-01")} 
                        maxDate={new Date("2033-01-30")} 
                    /> 
                </div>
            </li>
        </ul>

         */}
    </>
  )
}
