import React, { useEffect, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import Select from "react-select";

import Datepicker from 'react-tailwindcss-datepicker';
import moment from 'moment';
import Swal from 'sweetalert2';

import usePrivateApi from '../../../hooks/usePrivateApi';
import { saveCompra, setActiveCompra } from '../../../store/compras';

export const FormCompras = () => {
    const { api } = usePrivateApi();
    const dispatch = useDispatch();

    const {activeCompra} = useSelector(state=>state.compras);    
    const {laboratorios=[]} = useSelector(state => state.laboratorios);

    const { 
        register, handleSubmit, formState: { errors, isSubmitting }, control,setValue, watch
    } = useForm();
    
    
    useEffect(() => {
        const periodo = { 
            startDate: moment(activeCompra.fechaComprasVentasDesde),
            endDate: moment(activeCompra.fechaComprasVentasHasta),
        }
        setValue('descripcion',activeCompra.descripcion);
        setValue('cantidadDiasParaStock',activeCompra.cantidadDiasParaStock);
        setValue('laboratorio',{value:activeCompra.laboratorio.id,label:activeCompra.laboratorio.nombre})
        setValue('periodoCompra',periodo);        
    }, [activeCompra]);    

    const onSubmit = values => {
        const dataFormatted = {
            descripcion:values.descripcion,  
            cantidadDiasParaStock: values.cantidadDiasParaStock,         
            fechaComprasVentasDesde:moment(values.periodoCompra.startDate).valueOf(),
            fechaComprasVentasHasta:moment(values.periodoCompra.endDate).valueOf(),
            fechaFin:null,
            fechaInicio:moment().valueOf(),
            id:activeCompra.id,
            laboratorio:{...activeCompra.laboratorio,id:values.laboratorio.value},            
            productos:[],            
        }          
        dispatch(setActiveCompra(dataFormatted));         
        
        Swal.fire({
            title: '¿Guardar cambios sobre la compra?',  
            text: "",
            icon: 'warning',    
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Sí, guardar los cambios!',
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return dispatch(saveCompra({api}))                     
                .then(response => {                        
                  if (!response.ok) {
                     throw new Error(response.statusText)
                   }
                   return response.json();
                })
                .catch(error => {
                  Swal.showValidationMessage(
                    `Requerimiento Fallido: ${error}`
                  )
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {        
              Swal.fire({
                title: `${result.value}`,          
              })
            }
          })
    }

    const calendarRef = useRef(null);

  return (
    <>
        <form onSubmit={handleSubmit(onSubmit)} id="idFormNuevaAuditoria">
                
            <div className="mb-3">
                <label htmlFor="descripcion" className="form-label">Descripci&oacute;n</label>
                <input  type="Text"
                        name="descripcion"
                        className="form-control" 
                        autoComplete="off"                                
                        placeholder='Identificación de la compra'
                        {...register("descripcion", 
                                {required: "Descripcion es requerida",
                                minLength: {value:4, message:'Debe tener al menos 4 caracteres'},
                                maxLength: {value:30, message:'Máximo 30 caracteres'},
                                })
                        }                                
                />                        
                {<p className="text-danger">{errors.descripcion?.message} </p>}
            </div>

            <div className="mb-3">
                <label htmlFor="laboratorio" className="form-label">Laboratorio</label> 
                <Controller
                    name="laboratorio"
                    defaultValue={null}
                    control={control}                                 
                    rules={{ required: true }}
                    render={({ field }) => (
                        <Select 
                            {...field} 
                            isDisabled={!!activeCompra.id}
                            options={laboratorios.map(({id,nombre})=>({value:id,label:nombre}))} 
                        />
                    )}
                /> 
                {errors.laboratorio && (<p className="text-danger">Este campo es requerido</p>)}
            </div>     

            <Calendario innerRef={calendarRef} control={control} activeCompra={activeCompra}/>            

            <div className="mt-3 mb-3">
                <label htmlFor="cantidadDiasParaStock" className="form-label">Cant. d&iacute;as Stock</label>
                <input  type="Text"
                        name="cantidadDiasParaStock"
                        className="form-control" 
                        autoComplete="off"
                        disabled={!!activeCompra.id}                                
                        placeholder='Indicar la cant. días de stock'
                        {...register("cantidadDiasParaStock", 
                                {required: "Cant. dias Stock es requerida",
                                minLength: {value:2, message:'Debe tener al menos 2 caracteres'},
                                maxLength: {value:3, message:'Máximo 3 caracteres'},
                                min: {value:30, message:'Mínimo de 30 días'},
                                max: {value:365, message:'Máximo de 365 días'},
                                })
                        }                                
                />                        
                {<p className="text-danger">{errors.cantidadDiasParaStock?.message} </p>}
            </div>

            <div className='mb-2'>
                <button className='btn btn-primary' disabled={isSubmitting}>
                    {(isSubmitting) ? 'Actualizando...' : 'Guardar'}
                </button>

                {/* <button type="submit"
                    className={`group  mt-2 mb-2 relative flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                    disabled={isSubmitting} >
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">                                
                        {isSubmitting ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faPenToSquare} />}
                    </span>
                    {(isSubmitting) ? 'Actualizando...' : 'Guardar'}
                </button> */}
            </div>           
                
        </form>
    </>
  )
}

// https://bobbyhadz.com/blog/function-components-cannot-be-given-refs-in-react
const Calendario = ({innerRef, control, activeCompra}) => {
    return (
        <div className="mb-3">
            <label htmlFor="periodoCompra" className="form-label">Per&iacute;odo Base de Compras</label>
            {/* https://react-tailwindcss-datepicker.vercel.app/props */}
            <Controller
                name="periodoCompra"
                ref={innerRef} 
                control={control}                                 
                rules={{ required: true }}
                render={({ field }) => (                                  
                        <Datepicker 
                            {...field} 
                            useRange={false}
                            placeholder={"Mi período"}                                             
                            onChange={(e)=> field.onChange(e)} 
                            disabled={!!activeCompra.id}
                            showShortcuts={false} 
                            showFooter={false} 
                            displayFormat={"DD/MM/YYYY"} 
                            minDate={new Date("2020-01-01")} 
                            maxDate={new Date("2033-01-30")} 
                        />                                  
                        )}
            />
        
        
        </div>
    );
}