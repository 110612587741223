import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const useExport = () => {

    
    const onClickExportXLS = (apiData, fileName) => {
        const fileType ="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        
        const ws = XLSX.utils.json_to_sheet(apiData);
        /* custom headers */
        //XLSX.utils.sheet_add_aoa(ws, [["Auditoria", "Farmacia", "Laboratorio", "Administrador", "Inicio Aud.", "Fin Aud.", "Id.Aud", "Id.AudLab", "Id.Prod", "Fecha Stock", "# Paq. Stock", "# Fracc. Stock", "Fecha Inventario", "# Paq. Inventario", "# Fracc. Inventario"]], { origin: "A1" });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      }


  
    return {
        onClickExportXLS,
    }
}
