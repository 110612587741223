import React from 'react';

export const ProductoItemRenglon = ({clave,valor}) => {
  return (
    <div className="d-flex mb-1 align-items-center">
        <div className="fw-semibold text-dark me-auto p-2">{clave}</div>
        <div className="fw-normal small text-black-50 p-2">{valor}</div>        
    </div>
  )
}
