import React from 'react'
import { useRef } from 'react'
import { useEffect } from 'react';
import { useSelector } from 'react-redux'
import * as bootstrap from 'bootstrap';

const InfoComponente = () => {    
    const popoverRef = useRef();

    useEffect(() => {
        var popover = new bootstrap.Popover(popoverRef.current, {
            content: "Debe guardar para ver los totales actualizados!!!",
            title: "Info sobre totales"
        })
    }, []);

    return (        
        <button type="button" className="btn btn-warning" ref={popoverRef}>
            + Info
        </button>
    );
}

const DetalleTotal = ({clave,valor}) => {
    return (
        <ul className="list-group list-group-horizontal px-2">
            <li className="list-group-item fs-6 text-left text-uppercase fw-bold text-break">{clave}</li>
            <li className="list-group-item fs-6 text-left text-break ">{valor}</li>
        </ul>
    );
}

export const ComprasTotales = ({totalesCompra}) => {

    const {thereIsAProductoUpdated} = useSelector (state => state.compras);
    const destacar = thereIsAProductoUpdated
                        ? 'border border-warning'
                        : '';

   return (
    <>
        <div className={`${destacar} d-flex justify-content-end align-items-center`}>
            <DetalleTotal clave={'#Productos :'} valor={totalesCompra.cantidadProductos} />
            <DetalleTotal clave={'#Paquetes :'} valor={totalesCompra.cantidadPaquetes} />
            <DetalleTotal clave={'#Total :'} valor={totalesCompra.totalCompra} />
            {thereIsAProductoUpdated ? <InfoComponente /> :''}
        </div>
    </>        
  )
}
