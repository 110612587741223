import MenuPrincipal from "../components/UI/Menu/MenuPrincipal";

const MainLayout = (props) => {
  return (
    <>
      <main>
        <div>
          <div className="flex flex-row" style={{ height: "90vh" }}>
            <div>
              <MenuPrincipal />
            </div>
            {/* Contenido de cada páginas */}
            <div className="p-3 w-5/6">{props.children}</div>
            {/*  */}
          </div>
        </div>
      </main>
    </>
  );
};

export default MainLayout;
