import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthorizarionRequired = ({ allowedRoles }) => {
  const auth = useSelector((state) => state.auth);

  const roles = JSON.parse(localStorage.getItem("roles"));

  const location = useLocation();

  return roles && roles.find((role) => allowedRoles?.includes(role)) ? (
    <Outlet />
  ) : auth?.username && auth.loggedIn ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default AuthorizarionRequired;
