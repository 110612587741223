import { Link, useLocation } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

import {
  PATH_ESTADISTICAS,
  PATH_SISTEMA,
  PATH_STOCK,
} from "../../../util/ConstantesRouter";

const navigationStock = [
  { name: "Productos", path: "/productos" },
  { name: "Drogas", path: "/drogas" },
  { name: "Acc. Terapéuticas", path: "/accionesTerapeuticas" },
  { name: "Laboratorios", path: "/laboratorios" },
  { name: "Auditorias", path: "/auditorias" },
  { name: "Compras", path: "/compras" },
];

const MenuPrincipal = () => {
  const location = useLocation();

  return (
    <>
      <Sidebar style={{ height: "100%" }}>
        <Menu>
          <SubMenu
            defaultOpen={location.pathname.includes("/stock")}
            label="STOCK"
            className="text-sm font-semibold"
          >
            {navigationStock.map((item) => (
              <MenuItem
                active={location.pathname === item.path}
                routerLink={<Link to={PATH_STOCK + item.path} />}
                key={item.path}
                className="font-normal"
              >
                {item.name}
              </MenuItem>
            ))}
          </SubMenu>
          <MenuItem
            routerLink={<Link to={PATH_ESTADISTICAS} />}
            active={location.pathname === { PATH_ESTADISTICAS }}
            className="text-sm font-semibold"
          >
            ESTADÍSTICAS
          </MenuItem>
          <MenuItem
            routerLink={<Link to={PATH_SISTEMA} />}
            active={location.pathname === { PATH_SISTEMA }}
            className="text-sm font-semibold"
          >
            SISTEMA
          </MenuItem>
        </Menu>
      </Sidebar>
    </>
  );
};

export default MenuPrincipal;
