import React, { useEffect, Suspense } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route } from "react-router-dom";

import { authActions } from "./store/authSlice";
import {
  ROL_ADMINISTRADOR,
  ROL_USUARIO_ADMINISTRACION,
  ROL_USUARIO_AUDITORIA,
  ROL_USUARIO_COMPRAS,
  ROL_USUARIO_GERENCIA,
  ROL_USUARIO_MOSTRADOR,
} from "./util/ConstantesAuth";
import LoginRequired from "./components/LoginRequired";
import {
  PATH_ACC_TERAPEUTICAS,
  PATH_DROGAS,
  PATH_ESTADISTICAS,
  PATH_LABORATORIOS,
  PATH_AUDITORIAS,
  PATH_COMPRAS,
  PATH_LOGIN,
  PATH_LOGOUT,
  PATH_PRODUCTOS,
  PATH_SISTEMA,
  PATH_STOCK,
  PATH_UNAUTHORIZED,
  PATH_AUDITORIAS_EDITAR,
  PATH_AUDITORIAS_ALTA,
  PATH_AUDITORIAS_EDITAR_PRODUCTO_AUDITADO,
} from "./util/ConstantesRouter";
import AuthorizarionRequired from "./components/AuthorizationRequired";
import LottieLoader from "./components/UI/Loader/LottieLoader";
import { Auditorias } from "./pages/Auditorias";
import { EditarAuditoria } from "./pages/auditorias/EditarAuditoria";
import { AltaAuditoria } from "./pages/auditorias/AltaAuditoria";
import { EditarProductoAuditado } from "./pages/auditorias/EditarProductoAuditado";
import { ComprasAce } from "./pages/compras/ComprasAce";

const Inicio = React.lazy(() => import("./pages/Inicio"));
const Login = React.lazy(() => import("./pages/Login"));
const Logout = React.lazy(() => import("./pages/Logout"));
const Stock = React.lazy(() => import("./pages/Stock"));
const Sistema = React.lazy(() => import("./pages/Sistema"));
const Laboratorios = React.lazy(() => import("./pages/Laboratorios"));
const Notificaciones = React.lazy(() => import("./pages/Notificaciones"));
const AccionesTreapeuticas = React.lazy(() =>
  import("./pages/AccionesTerapeuticas")
);
const Drogas = React.lazy(() => import("./pages/Drogas"));
const DefaultHeader = React.lazy(() => import("./layout/DefaultHeader"));
const Unauthorized = React.lazy(() => import("./pages/Unauthorized"));

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.loadAuthState());
  }, [dispatch]);

  return (
    <>
      <Suspense
        fallback={() => {
          <div className="center">
            <LottieLoader />
          </div>;
        }}
      >
        <Routes>
          {/* Rutas públicas */}
          <Route path={PATH_LOGIN} element={<Login />} />

          <Route element={<DefaultHeader />}>
            <Route path={PATH_UNAUTHORIZED} element={<Unauthorized />} />

            {/* Rutas privadas */}

            {/* Rutas para consulta de STOCK */}
            <Route path={PATH_STOCK}>
              {/* Ruta para consultar stock por NOMBRE */}
              <Route
                element={
                  <AuthorizarionRequired
                    allowedRoles={[ROL_USUARIO_MOSTRADOR, ROL_USUARIO_GERENCIA]}
                  />
                }
              >
                <Route path={PATH_PRODUCTOS} element={<Stock />} />
              </Route>

              {/* Ruta para consultar stock por DROGA */}
              <Route
                element={
                  <AuthorizarionRequired
                    allowedRoles={[ROL_USUARIO_MOSTRADOR, ROL_USUARIO_GERENCIA]}
                  />
                }
              >
                <Route path={PATH_DROGAS} element={<Drogas />} />
              </Route>

              {/* Ruta para consultar stock por ACCIÓN TERAPÉUTICA */}
              <Route
                element={
                  <AuthorizarionRequired
                    allowedRoles={[ROL_USUARIO_MOSTRADOR, ROL_USUARIO_GERENCIA]}
                  />
                }
              >
                <Route
                  path={PATH_ACC_TERAPEUTICAS}
                  element={<AccionesTreapeuticas />}
                />
              </Route>

              {/* Ruta para consultar stock por LABORATORIO */}
              <Route
                element={
                  <AuthorizarionRequired
                    allowedRoles={[ROL_USUARIO_COMPRAS, ROL_USUARIO_GERENCIA]}
                  />
                }
              >
                <Route path={PATH_LABORATORIOS} element={<Laboratorios />} />
              </Route>

              {/* Ruta para realizar/consultar auditorias de stock */}
              <Route
                element={
                  <AuthorizarionRequired
                    //allowedRoles={[ROL_USUARIO_MOSTRADOR,ROL_USUARIO_COMPRAS, ROL_USUARIO_GERENCIA]}
                    allowedRoles={[
                      ROL_USUARIO_ADMINISTRACION,
                      ROL_USUARIO_AUDITORIA,
                    ]}
                  />
                }
              >
                <Route path={PATH_AUDITORIAS} element={<Auditorias />} />
              </Route>

              {/* Ruta para dar de alta una auditoria Madre */}
              <Route
                element={
                  <AuthorizarionRequired
                    //allowedRoles={[ROL_ADMINISTRADOR,ROL_USUARIO_COMPRAS, ROL_USUARIO_GERENCIA]}
                    allowedRoles={[ROL_USUARIO_ADMINISTRACION]}
                  />
                }
              >
                <Route
                  path={PATH_AUDITORIAS_ALTA}
                  element={<AltaAuditoria />}
                />
              </Route>

              {/* Ruta para editar una auditoria Madre */}
              <Route
                element={
                  <AuthorizarionRequired
                    //allowedRoles={[ROL_USUARIO_MOSTRADOR,ROL_USUARIO_COMPRAS, ROL_USUARIO_GERENCIA]}
                    allowedRoles={[ROL_USUARIO_ADMINISTRACION]}
                  />
                }
              >
                <Route
                  path={PATH_AUDITORIAS_EDITAR}
                  element={<EditarAuditoria />}
                />
              </Route>

              {/* Ruta para editar un PRODUCTO AUDITADO */}
              <Route
                element={
                  <AuthorizarionRequired
                    //allowedRoles={[ROL_USUARIO_MOSTRADOR,ROL_USUARIO_COMPRAS, ROL_USUARIO_GERENCIA]}
                    allowedRoles={[
                      ROL_USUARIO_ADMINISTRACION,
                      ROL_USUARIO_AUDITORIA,
                    ]}
                  />
                }
              >
                <Route
                  path={PATH_AUDITORIAS_EDITAR_PRODUCTO_AUDITADO}
                  element={<EditarProductoAuditado />}
                />
              </Route>

              {/* Ruta para realizar/consultar COMPRAS */}
              <Route
                element={
                  <AuthorizarionRequired
                    allowedRoles={[
                      ROL_USUARIO_ADMINISTRACION,
                      ROL_USUARIO_COMPRAS,
                    ]}
                  />
                }
              >
                <Route path={PATH_COMPRAS} element={<ComprasAce />} />
              </Route>
            </Route>

            {/* Ruta para estadísticas de la app */}
            <Route
              element={
                <AuthorizarionRequired
                  allowedRoles={[
                    ROL_USUARIO_ADMINISTRACION,
                    ROL_USUARIO_GERENCIA,
                  ]}
                />
              }
            >
              <Route path={PATH_ESTADISTICAS} element={<Inicio />} />
            </Route>

            {/* Ruta para estado general de la app */}
            <Route
              element={
                <AuthorizarionRequired
                  allowedRoles={[ROL_ADMINISTRADOR, ROL_USUARIO_GERENCIA]}
                />
              }
            >
              <Route path={PATH_SISTEMA} element={<Sistema />} />
            </Route>

            {/* Ruta para enviar notificaciones a usuarios desde la app */}
            <Route
              element={
                <AuthorizarionRequired
                  allowedRoles={[ROL_ADMINISTRADOR, ROL_USUARIO_GERENCIA]}
                />
              }
            >
              <Route path="notificaciones" element={<Notificaciones />} />
            </Route>

            {/* Route para hacer LOGOUT */}
            <Route path={PATH_LOGOUT} element={<Logout />} />
            <Route element={<LoginRequired />}>
              <Route path="*" element={<Inicio />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
