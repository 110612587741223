import { getLaboratoriosAPI} from "./../../api/laboratorios";
import { setLaboratorios} from "./laboratoriosSlice";

export const startLoadingLaboratorios = (api) => {
    return async (dispatch,getSate) => {          
        const data =  await getLaboratoriosAPI(api);               
        dispatch(setLaboratorios({laboratorios: data.data}));        
    }
}


export const getLaboratorios = ( api ) => {
    return async(dispatch, getState) => {
        dispatch(startLoadingLaboratorios());
        const data =  await api.get("/laboratorios/");        
        dispatch(setLaboratorios({laboratorios: data.data}));
    }
}