import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const compraEmpty = {
    "id":null,
    "laboratorio":{id:null},
    "descripcion": "",
    "usuario": {"id": null},
    "fechaIni": null,
    "fechaFin": null,
    "productosFarmacias":[],
    "productos": [],
    "fcomprasVtasHasta": null,
    "fcomprasVtasDesde": null,
    "cantidadDiasParaStock":45
};

const filterBase = {        
    "condicionCompraAce": "opened",    
    "fhIni": moment().subtract(1,'month').valueOf(), 
    "fhFin":  moment().valueOf(),
    "laboratorio": {"id": 0},          
};

export const comprasSlice = createSlice({
   name: 'compras',
   initialState: {      
        "isLoadingCompras":false,
        "isLoadingProductos":false,
        "isSavingCompra"  :false,
        "isSavingProducto":false,  
        "thereIsAProductoUpdated":false,      
        "compras":[],
        "activeCompra": null,
        "productos":[],
        "activeProducto": null,
        "messageSaved":'',
        "filterCompras": filterBase,
   },
   reducers: {
        //filtros
        setFilterCompraCondicionCompra: (state,action) =>{
            state.filterCompras.condicionCompraAce = action.payload;
        },
        setFilterCompraLaboratorio: (state,action) =>{
            state.filterCompras.laboratorio = action.payload;
        },
        setFilterCompraPeriodo: (state, action) => {
            state.filterCompras.fhIni = action.payload.fhIni;
            state.filterCompras.fhFin = action.payload.fhFin;
        },

        //compras
        startLoadingCompras: (state) => {
            state.isLoadingCompras= true;
            state.compras = [];
            state.activeCompra = null;
            state.productos = [];
            state.activeProducto = null;
        },
        finishLoadingCompras: (state) => {
            state.isLoadingCompras= false;
        },
        setCompras: (state,action) => {                
            state.activeCompra = null;
            state.productos=[];
            state.activeProducto = null;
            state.compras = action.payload;
            state.isLoadingCompras= false;
        },
        setActiveCompra: (state,action) => {  
            state.activeCompra = action.payload; 
            state.productos=action.payload.productos;   
            state.activeProducto = null;  
            state.thereIsAProductoUpdated = false;       
        },
        startSavingCompra: (state) => {
            state.isSavingCompra = true;
        },
        finishSavingCompra: (state) => {
            state.isSavingCompra = false;
        },
        addNewEmptyCompra: (state, /* action */ ) => {            
            state.compras.push(compraEmpty);
            state.activeCompra=compraEmpty;
            state.productos=compraEmpty.productos;
            state.activeProducto = null;
            state.isSavingCompra=false;            
        },        
        updateCompra: (state, action) => { 
            state.compras = state.compras.map(compra => {
                if(compra.id === action.payload.id || compra.id === null){ 
                    state.activeCompra=action.payload;   
                    state.productos=action.payload.productos;  
                    state.activeProducto = null;              
                    return action.payload;
                } else {                    
                    return compra;
                }    
            });
            state.isSavingCompra = false;
        },
        deleteCompra: (state, action) => {
            state.compras = state.compras.filter(compra =>compra.id !== action.payload.id);
            state.isSavingCompra = false;
        },

        //productos
        setThereIsAProductoUpdate: (state,action) => {            
            state.thereIsAProductoUpdated = true;             
        },        
        setActiveProducto: (state,action) => {            
            state.activeProducto = action.payload;             
        },        
        startSavingProductos: (state) => {
            state.isSavingProducto = true;
        },
        updateProductos: (state,action) => {
            //actualizo los productos
            state.productos = state.productos.map( producto => {      
                const productoFound = action.payload.find (prodUpdated => prodUpdated.id === producto.id);
                if (productoFound !== undefined)
                    return productoFound;
                else 
                    return producto;                
            });            

            //actualizar la compraActiva
            state.activeCompra.productos = state.productos;

            //actualizar las compras
            state.compras = state.compras.map( compra => {
                if (compra.id === state.activeCompra.id)
                    return state.activeCompra;
                else
                    return compra;                
            });

            state.thereIsAProductoUpdated=false;
            state.activeProducto = null;
            state.isSavingProducto = false;
        },

        



   }
});


// Action creators are generated for each case reducer function
export const { 
    setFilterCompraCondicionCompra,
    setFilterCompraLaboratorio,
    setFilterCompraPeriodo,

    startLoadingCompras,
    finishLoadingCompras,
    setCompras,
    setActiveCompra,
    startSavingCompra,
    finishSavingCompra,
    addNewEmptyCompra,    
    updateCompra,
    deleteCompra,

    setThereIsAProductoUpdate,
    setActiveProducto,
    startSavingProductos,
    updateProductos,
} = comprasSlice.actions;