export const ROL_USUARIO_MOSTRADOR = "USUARIO_MOSTRADOR";

export const ROL_USUARIO_ADMINISTRACION = "USUARIO_ADMINISTRACION";

export const ROL_USUARIO_GERENCIA = "USUARIO_GERENCIA";

export const ROL_USUARIO_COMPRAS = "USUARIO_COMPRAS";

export const ROL_ADMINISTRADOR = "ADMINISTRADOR";

export const ROL_USUARIO_AUDITORIA = "USUARIO_AUDITORIA";