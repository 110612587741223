import React from 'react'
import { useNavigate } from 'react-router-dom'

export const BtnRegresar = ({path = '/'}) => {
    const navigate = useNavigate();
  return (    
    <button
        type="button"
        className="border border-gray-700 bg-gray-700 text-white rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-gray-800 focus:outline-none focus:shadow-outline"
        onClick={()=>navigate(path)}
      >
        Regresar
     </button>
  )
}
