import React from 'react'
import { FormularioAuditoria } from '../../components/UI/Auditoria/FormularioAuditoria'
import { BtnRegresar } from '../../components/UI/Botones/BtnRegresar'
import MainLayout from '../../layout/MainLayout'
import { PATH_AUDITORIAS, PATH_STOCK } from '../../util/ConstantesRouter'

export const AltaAuditoria = () => {
  const pathReturn = PATH_STOCK + '/' + PATH_AUDITORIAS;
  return (
    <MainLayout>
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Nueva Auditoría
      </h2>    
      <BtnRegresar path={pathReturn}/>
      <FormularioAuditoria />
    </MainLayout>
  )
}