import React from 'react'
import { CardAuditoriaItem } from './CardAuditoriaItem';
import { useSelector } from 'react-redux';


export const CardAuditoriaContainer = () => {
  
  const { auditorias } = useSelector(state => state.auditorias);

  return (    
    <div className="grid grid-cols-1 sm:grid-cols-3">        
        {auditorias.map((auditoria)=>(                    
            <CardAuditoriaItem key={auditoria.id} {...auditoria} />
        ))}        
    </div>     
  )
}
