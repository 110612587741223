import { useLottie } from "lottie-react";
import loadingAnimation from "../../../assets/loadingAnimation.json";

const LottieLoader = ({ size }) => {
  const style = {
    height: size,
  };

  const options = {
    animationData: loadingAnimation,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options, style);

  return <div>{View}</div>;
};

export default LottieLoader;
