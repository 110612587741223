//Recupera todas las compras
export const getComprasAceApi = async ({ api }) => {
  return await api.get("/compras");
};

//Agrega una nueva compra
export const addCompraApi = async ({ api }, data) => {
  return await api.post("/compras/", data);
};

//Actualiza una compra
export const updateCompraApi = async ({ api }, data) => {
  return await api.put("/compras/", data);
};

//Recupera todas las compras aplicando filtros
export const getComprasAceFiltersApi = async ({ api }, filter) => {
  return await api.post("/compras/filtrarCompras", filter);
};

//Actualiza un producto
export const updateProductoApi = async ({ api }, data = []) => {
  return await api.put("/compras/updateCompraAceProd", data);
};

//Recupera los totales de una compra
export const getTotalesCompraAceByIdApi = async ({ api }, id = 0) => {
  return await api.get(`/compras/listTotalesCompra/${id}`);
};

//Recupera los detalles por farmacia totales de una compra y un producto
export const getDetallesTotalesCompraByFarmaciaByIdApi = async (
  { api },
  idCompraAce = 0,
  idProducto = 0
) => {
  return await api.get(
    `/compras/listDetallesTotalesCompraXFarmacias?idCompraAce=${idCompraAce}&idProducto=${idProducto}`
  );
};

//Recupera los detalles de la ACE con los totales de una compra y un producto
export const getDetallesTotalesCompraAceByIdApi = async (
  { api },
  idCompraAce = 0,
  idProducto = 0
) => {
  return await api.get(
    `/compras/listDetallesTotalesCompraACE?idCompraAce=${idCompraAce}&idProducto=${idProducto}`
  );
};
