import { createSlice } from "@reduxjs/toolkit";

const errorSlice = createSlice({
  name: "auth",
  initialState: {
    visible: false,
    titulo: "",
    mensaje: "",
  },
  reducers: {
    showModalError(state, action) {
      const { titulo, mensaje } = action.payload;

      state.titulo = titulo;
      state.mensaje = mensaje;
      state.visible = true;
    },
    hideModalError(state) {
      state.visible = false;
      state.titulo = "";
      state.mensaje = "";
    },
  },
});

export const errorActions = errorSlice.actions;

export default errorSlice;
