//Recupera todas las auditorias
export const getAuditoriasAPI = async ({ api } ,pCondicion) => { 
    //console.log('getAuditoriasAPI-->',pCondicion);         
    return await api.post("/auditorias/filtrarAuditorias",pCondicion); 
};

//Recupera una nueva auditoria por su ID
export const getAuditoriaByIdAPI = async ({ api }, id ) => {       
    const resp = await api.get(`/auditorias/${id}/`);    
    return resp;    
};

//Agrega una nueva auditoria
export const addAuditoriaAPI = async ({ api }, data ) => {          
     return await api.post("/auditorias/", data);    
};

//Actualiza los datos de una auditoria madre
export const updateAuditoriaAPI = async ({ api}, data ) => {        
    //console.log('updateAuditoriaAPI',api,data) ;
    return await api.put("/auditorias/", data );    
};

//Actualiza los datos de una auditoria madre
export const finalizarAuditoriaAPI = async ({ api}, data ) => {        
    //console.log('finalizarAuditoriaAPI',api,data) ;
    return await api.put(`/auditorias/finalizar/${data.id}/`);    
};

//Elimina una auditoria madre
export const deleteAuditoriaAPI = async ({ api}, id ) => {            
    //console.log('deleteAuditoriaAPI',api,id) ;        
    return await api.delete(`/auditorias/${id}/`);

};

//Actualiza los datos de un productoAuditado con id
export const updateProductoAuditadoAPI = async ({api}, data) => {    
    // const dataMaped = {
    //     "cantidadFraccionesInventario":"3333",
    //     "cantidadPaquetesInventario":"4444",    
    //     "fechaInventario":"1572714855555"
    // }
    return await api.put(`/auditorias/productosAuditados/${data.id}`, data );    
};




//Agrega una nueva auditoria madre
export const addAuditoria = async ({ api, data }) => {  
    // const dataMaped = {
    //     farmacia:{id:data.farmacia.value},
    //     laboratorio:{id:data.laboratorio.value},
    //     fechaInicio:data.fechaInicio.startDate,        
    //     descripcion:data.descripcion,
    // }    
    // return await api.post("/auditorias/", dataMaped );  
    console.log('en Auditorias.js voy a realizar la invocación ');
     return await api.post("/auditorias/", data);    
};

//Actualiza los datos de una auditoria madre
export const updateAuditoria = async ({ api, data }) => {    
    return await api.put("/auditorias/", data );    
};

//Actualiza los datos de un productoAuditado con id
export const updateAuditoriaProductoAuditado = async ({ api, data }) => {    
    // const dataMaped = {
    //     "cantidadFraccionesInventario":"3333",
    //     "idad":"4444",    
    //     "fechaInventario":"1572714855555"
    // }
    return await api.put(`/auditorias/productosAuditados/${data.id}`, data );    
};