import { apiPublica as api } from "./axios";

export const login = async ({ username, password }) => {
  return await api.post("/login", {
    username,
    password,
  });
};

export const getNewToken = async () => {
  return await api.get("/refreshToken");
};
